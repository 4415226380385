@import '../../../../../../../resources/scss/breakpoints.scss';

.hero-experience {

    //align-self: center;
    position: relative;
    height: 100%;
    margin: 0 auto;

    // instead of defaulting all to display none and using class to set a child display property
    // which forces us to choose that property which the child may not prefer (block vs flex vs grid)
    // by using not to individually select the children to hide achieves the same effect
    // while making no assumptions to the preferred css properties of the children

	&:not(.last-viewed) {
        .tire-last-viewed {
            display: none;
        }
    }

    &:not(.known-vehicle) {
        .known-vehicle {
            display: none;
        }
    }

    &:not(.unknown) {
        .vehicle-picker-widget {
            display: none;
        }
    }
}
