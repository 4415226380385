@import '../../../../../../../resources/scss/breakpoints.scss';

.known-vehicle {

	display: block;
	
	h2, .h2{
		margin: 20px auto 10px;
	}

	a{
		margin: 10px auto;
		min-width: 180px;
	}

	// not sure we should be scoping to button--tertiary, not enterprise sharable convention
	.button--tertiary{
		width: 80%;
	}

}