@import '../../../../../../../resources/scss/global.scss';

.tire-last-viewed {

	display: block;

	// this feels like it should be in the theme css
	.cmp-tire-card-search.tire-card--summary h3{
		height: auto;
		padding-bottom: 14px;
	}
	
	// why is this needed, most of it should be handled by (consistant with) the ratings css
	.tire-rating {
		&__summary {
			display: flex; 
			flex-flow: row nowrap; 
			justify-content: center; 
			align-items: center; 
			font-size: 14px; 
			
			.stars {
				width: 80px; 
				height: 16px; 
				
				&:before {
					width: 80px; 
					background-size: 16px;
					height: 16px;
				}
				
				> span {
					height: 16px; 
					
					&:before {
						background-size: 16px;
						height: 16px;
					}
				
				}
			}
			
			.averageRating {
				margin-left: 3px;  
				margin-right: 2px; 
			}
		}
	}
}