@import '../../../../../../../resources/scss/breakpoints.scss';

.vehicle-picker-widget {
	
	display: block; 
	
	&__year {
	
	}
	
	&__options {
		> span {
			display: block; 
			padding: 10px 0; 
			cursor: pointer;
			
			&:last-child {
				margin-bottom: 0;
			}
			
			&.active {
				/* generic color */
				background-color: #ccc; 
			}
		}
	}
	
	&--next {
	
	}
}